<template>
  <v-container class="fill-height">
    <v-card flat class="justify-center pa-2">
      <v-form @submit.prevent="prepSubmit" ref="form" v-model="formValid" v-on:keydown.enter="$event.stopPropagation()">
        <v-card class="mx-auto pa-6 pb-8" elevation="3" rounded="lg" :max-width="cardMaxWidth">
          <v-row align="center" justify="center" no-gutters>
            <v-col cols="12" md="4" class="d-flex justify-center mb-4 mb-md-0" v-if="faviconImage || lightImage">
              <v-img
                :src="logoImage"
                alt="Protected"
                :max-height="imageMaxHeight"
                :max-width="imageMaxWidth"
                contain
              ></v-img>
            </v-col>
            <v-col cols="12" md="8">
              <div class="text-subtitle-2 text-medium-emphasis">Name</div>
              <v-text-field
                :density="textFieldDensity"
                placeholder="Name"
                prepend-inner-icon="mdi-account-outline"
                v-model.trim="user.name"
                type="text"
                :rules="fieldRequired"
                solo
                outlined
                :tabindex="1"
                :success="isFieldValid(user.name)"
              ></v-text-field>

              <div class="text-subtitle-2 text-medium-emphasis">Email</div>
              <v-text-field
                :density="textFieldDensity"
                placeholder="Email Address"
                prepend-inner-icon="mdi-email-outline"
                v-model.trim="user.email"
                type="email"
                :rules="fieldRequired"
                solo
                outlined
                :tabindex="2"
                :success="isFieldValid(user.email)"
              ></v-text-field>

              <div class="text-subtitle-2 text-medium-emphasis d-flex align-center justify-space-between">
                Password
              </div>
              <v-text-field
                :append-icon="visible1 ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible1 ? 'text' : 'password'"
                :density="textFieldDensity"
                placeholder="Enter password"
                prepend-inner-icon="mdi-lock-outline"
                @click:append="visible1 = !visible1"
                v-model.trim="user.password"
                :rules="fieldRequired"
                solo
                outlined
                :tabindex="3"
                :success="isFieldValid(user.password)"
              ></v-text-field>

              <div class="text-subtitle-2 text-medium-emphasis d-flex align-center justify-space-between">
                Confirm Password
              </div>
              <v-text-field
                :append-icon="visible2 ? 'mdi-eye-off' : 'mdi-eye'"
                :type="visible2 ? 'text' : 'password'"
                :density="textFieldDensity"
                placeholder="Confirm password"
                prepend-inner-icon="mdi-lock-outline"
                solo
                outlined
                @click:append="visible2 = !visible2"
                v-model.trim="confirmPassword"
                :tabindex="4"
                :rules="
                  fieldRequired.concat(() => {
                    return (
                      confirmPassword === user.password ||
                      'Password must match'
                    );
                  })
                "
                :success="isFieldValid(confirmPassword) && confirmPassword === user.password"
              ></v-text-field>

              <v-dialog persistent v-model="dialog" max-width="800px">
                <template v-slot:activator="{ on }">
                  <v-flex px-2>
                    <v-checkbox v-model="checkbox" readonly v-on="on" :tabindex="5">
                      <template v-slot:label>
                        <div>
                          I have read and agree to the
                          <a href="#" class="text-decoration-none">
                            Terms of Service</a
                          >
                        </div>
                      </template>
                    </v-checkbox>
                  </v-flex>
                </template>
                <v-card>
                  <v-card-title class="text-h5">
                    Terms of Service Agreement
                  </v-card-title>
                  <v-divider />
                  <v-card-text class="text-justify">
                    <v-card-text class="text-justify" v-html="latestDocumentContent"></v-card-text>
                  </v-card-text>
                  <v-divider />
                  <v-card-actions>
                    <v-btn
                      :disabled="checkbox"
                      color="blue darken-1"
                      text
                      @click="dialog = false"
                    >
                      Disagree
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="agreeTerms()">
                      Agree
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-btn
                class="my-2"
                :size="buttonSize"
                color="primary"
                rounded
                block
                type="submit"
                :disabled="!formValid || !checkbox"
                :tabindex="6"
              >
                Register
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-form>
    </v-card>
    <v-snackbar :color="snackColor" v-model="snackbar" :timeout="2000">{{ response }}</v-snackbar>

    <!-- Loading screen -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          <div class="text-center">
            <v-progress-circular indeterminate color="white" size="50"></v-progress-circular>
            <div class="mt-3">Registering...</div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { auth, usersCollection, termsCollection } from "../../firebaseDb";
import useLocalStorage from "../../composables/useLocalStorage";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      confirmPassword: "",
      formValid: true,
      checkbox: false,
      dialog: false,
      fieldRequired: [(v) => !!v || "Field is required"],
      user: {
        name: "",
        email: "",
        password: "",
      },
      latestDocumentContent: '',
      visible1: false,
      visible2: false,
      response: "",
      snackColor: "black",
      snackbar: false,
      loading: false, 
      currentTermDocId: '',
      lightImage: null,
      faviconImage: null,
    };
  },
  computed: { 
    ...mapGetters(["darkTheme"]),
    imageMaxHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? '300px' : '100px';
    },
    imageMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '300px' : '100px';
    },
    cardMaxWidth() {
      return this.$vuetify.breakpoint.mdAndUp ? '900px' : '100%';
    },
    textFieldDensity() {
      return this.$vuetify.breakpoint.mdAndUp ? 'compact' : 'comfortable';
    },
    buttonSize() {
      return this.$vuetify.breakpoint.mdAndUp ? 'large' : 'small';
    },
    logoImage() {
      return this.$vuetify.theme.dark ? this.lightImage : this.faviconImage;
    },
  },
  methods: {
    agreeTerms() {
      this.dialog = false;
      this.checkbox = true;
    },
    prepSubmit() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.userRegistration();
      }
    },
    isFieldValid(fieldValue) {
      return !!fieldValue && fieldValue.length > 0;
    },
    async userRegistration() {
      this.loading = true; // Show loading screen
      try {
        const res = await auth.createUserWithEmailAndPassword(this.user.email, this.user.password);
        this.$store.commit("setCurrentUser", res.user);

        // Create a Firestore document reference to the terms document
        const termAgreementRef = termsCollection.doc(this.currentTermDocId);

        // Set the user document in the users collection with a reference in termsArray
        await usersCollection.doc(res.user.uid).set({
          name: this.user.name,
          email: this.user.email,
          role: "Tier4",
          verified: false,
          termsArray: [termAgreementRef], // Store the document reference in the array
        });

        // Update the user's display name in Firebase Authentication
        await res.user.updateProfile({ displayName: this.user.name });

        // Fetch the user profile to update the Vuex store
        await this.$store.dispatch("fetchUserProfile");

        // Send a verification email to the user
        await res.user.sendEmailVerification();

        this.loading = false; // Hide loading screen
        localStorage.setItem('emailVerificationSent', 'true');
        this.$router.push('/');
      } catch (error) {
        this.loading = false;
        this.snackbar = true;
        this.snackColor = "error";
        this.response = error.message;
      }
    },
    fetchLatestDocument() {
        termsCollection
          .where("isActive", "==", true)
          .limit(1)
          .get()
          .then((querySnapshot) => {
            if (!querySnapshot.empty) {
              const activeTermDoc = querySnapshot.docs[0]; 
              this.currentTermDocId = activeTermDoc.id;  
              const activeTerm = activeTermDoc.data();

              this.latestDocumentContent = activeTerm.content;
            } else {
              this.latestDocumentContent = "No active terms found.";
            }
          })
          .catch((error) => {
            console.error("Error fetching active terms:", error);
            this.latestDocumentContent = "Error fetching active terms.";
          })
      }
  },
  created() {
    // Call the method when the component is created or when needed
    const faviconImageStorage = useLocalStorage(null, "faviconImage");
    const lightImageStorage = useLocalStorage(null, "lightImage");
    this.faviconImage = faviconImageStorage.get();
    this. lightImage = lightImageStorage.get();
    this.fetchLatestDocument();
  },
};
</script>
